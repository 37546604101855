.page-loading-div {
    display: block;
    font-size: 1.5em;
    margin: 19vh auto;
    text-align: center;
    width: 150px;
}

.page-loading-label {
    margin-top: 30px;
}