.sidebar-bottom-nav {
  display: block;
  margin-top: auto;
}

.sidebar-bottom-nav a {
  display: block;
  padding: .5rem 0 0 1rem;
  text-decoration: none;
}

.sidebar-bottom-nav a:hover {
  text-decoration: underline;
}