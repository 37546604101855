.login-description {
    padding-top: 3rem;
}

    .login-description h1 {
        text-align: center;
    }

.login-form {
    margin-top: 20vh;
}

.login-input-field {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-width: 0 !important;
}

.login-instructions {
    font-size: 1.2rem;
    padding-bottom: 1rem;
}

.login-one-time-password {
    letter-spacing: 0.5rem;
    text-align: center;
}

.login-submit-button {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}