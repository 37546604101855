.login-description {
    padding-top: 3rem;
}

    .login-description h1 {
        text-align: center;
    }

.email-and-password-form {
    margin-top: 20vh;
}

.email-and-password-form .form-floating {
    margin-bottom: 20px;
}