.account-dropdown, .batch-dropdown, .project-dropdown {
  margin-top: 1em;
}

@media only screen and (min-width: 576px) {
  .account-dropdown {
    margin-top: 0;
    margin-bottom: 1em;
  }

  .batch-dropdown, .project-dropdown {
    display: none;
  }
}