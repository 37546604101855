.project {
  border-bottom: 1px solid #b4b4b4;
  display: none;
  min-height: 0;
  position: relative;
  transition: min-height 1s;
}

.project-name {
  color: #484848;
  cursor: pointer;
  display: block;
  font-size: .9em;
  padding: 10px 0 10px 5px;
  text-decoration: none;
  text-transform: uppercase;
}

@media only screen and (min-width: 576px) {
  .project {
    display: block;
  }
}