.batch-list {
  list-style: none;
  padding: 0;
}

.batch-name {
  color: #484848;
  cursor: pointer;
  display: block;
  padding-left: 20px;
  text-decoration: none;
}

.batch-name:not(.active):hover {
  background: #FFF;
  border-right: 3px solid orange;
}

.batch-name.active {
  background: #FFF;
  border-right: 3px solid #0d6efd;
}