.testerly-logo-text {
  color: #4279a6;
  display: block;
  font-size: 1.4rem;
  font-family: "Tahoma", sans-serif;
  font-weight: 900;
  letter-spacing: .08rem;
  vertical-align: top;
  width: 50vw;
}

.testerly-logo-image {
  float: left;
  height: 30px;
  margin: 2px 5px 0 2px;
  width: 30px;
}

.logo-container {
  display: inline-block;
  margin-left: 3px;
  min-height: 30px;
  width: 50vw;
}

@media only screen and (min-width: 576px) {
  .testerly-logo-text {
    font-size: 2rem;
    width: 15rem;
  }

  .testerly-logo-image {
    height: 40px;
    margin-top: 8px;
    margin-left: 8px;
    width: 60px;
  }

  .logo-container {
    min-height: 90px;
    padding: 1rem 0;
  }
}