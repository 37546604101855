.main {
  transition: padding-left 1s;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background: radial-gradient(at top left, #eff3fc, #c7d4ec);
  border-bottom: 1px solid #87c5f7;
  padding: 0 0 1em;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.sidebar-container {
  position: relative;
  z-index: 10;
}

.sidebar-container.expanded {
  left: 0;
  padding-bottom: 15px;
}

.sidebar-container.collapsed, .sidebar-container.collapsed .sidebar {
  height: 40px;
  left: 0;
  overflow: hidden;
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.5em;
    right: 0.55em;
    position: absolute;
    top: 1.2em;
    transform: rotate(-45deg);
    transition: transform 1s;
    vertical-align: top;
    width: 0.5em;
  }

  .chevron.right:before {
    transform: rotate(45deg);
  }

  .chevron.bottom:before {
    top: 1em;
    transform: rotate(135deg);
  }

  .chevron.left:before {
    transform: rotate(-135deg);
  }

@media only screen and (min-width: 576px) {
  .main {
    padding-left: 25px;
    padding-right: .75rem;
  }

  .main.sidebar-expanded{
    padding-left: 260px;
  }

  .sidebar {
    background-color: #eeeeee;
    border-bottom: none;
    border-right: 1px solid #b4b4b4;
    height: 100vh;
    transition: none;
  }

  .sidebar-container {
    position: fixed;
    height: 100vh;
    transition: left 1s;
    width: 250px;
  }

  .sidebar-container.collapsed, .sidebar-container.collapsed .sidebar {
    height: 100vh;
    left: -235px;
    overflow: visible;
  }

  .sidebar-container.expanded {
    height: 100vh;
  }
}