.sidebar-toggler-sm {
  cursor: pointer;
  background: #fbfbfb;
  border: 1px solid #b4b4b4;
  border-radius: 50%;
  color: #0d6efd;
  display: none;
  height: 30px;
  padding: 0 3px;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -13px;
  transition: transform 1s;
  width: 30px;
}

.sidebar-toggler-sm:hover {
  background: yellow;
  color: dodgerblue;
}

.sidebar-container.collapsed .sidebar-toggler-sm {
  transform: rotate(180deg);
}

.sidebar-toggler-xs {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

@media only screen and (min-width: 576px) {
  .sidebar-toggler-xs {
    display: none;
  }

  .sidebar-toggler-sm {
    display: block;
  }
}