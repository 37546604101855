body {
  margin: 0;
}

.fade-in {
  animation: DefaultFadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.main {
  overflow-x: scroll;
  padding-top: 1em;
}

@keyframes DefaultFadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 576px) {
  .main {
    overflow-x: visible;
    padding-top: 0;
  }
}